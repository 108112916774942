import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'
import { Paragraph } from '../styled/generics'
import { APPOINTMENT_STAGE_NEW, SPECIALTIES_GROUPS, CLINICS_ID } from '../constants';
import AppointmentHeader from './AppointmentHeader'
import TabNewAppointment from './TabNewAppointment'
import { getSpecialties, getProfessionals, confirmAppointment } from '../api'
import FormSelectProfessional from './FormSelectProfessional'
import ScheduleAppointment from './ScheduleAppointment'
import FormConfirmAppointment from './FormConfirmAppointment'
import { setAppointmentDetails, setAppointmentStage } from '../actions'

class NewAppointment extends React.Component {
  state = {
    activeTab: 1,
    specialties: [],
    professionals: [],
    specialty: '',
    professionalSelected: '',
    professional: '',
    schedule: '',
    appointmentClinic: '',
    email: '',
    phone: '',
    appointmentId: 0
  }

  async componentDidMount() {
    console.log(APPOINTMENT_STAGE_NEW)
    this.props.setAppointmentStage(APPOINTMENT_STAGE_NEW);
        
    const attention = this.props.match.path.split('/')[3]
    const SpecialtiesGroup = SPECIALTIES_GROUPS[attention]

    const request = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      idProfesional: -1,
      idObraSocial: this.props.patient.idObraSocial,
      idPlan: this.props.patient.idPlan,
      SpecialtiesGroup: SpecialtiesGroup,
      esVideoconsulta: this.props.appointment.isOnline
    }

    const [err, { data } = {}] = await to(getSpecialties(request))
    if (!err) {
      this.setState({
        specialties: data
      })
    }
  }

  handleSpecialtyChange = async specialtyId => {
    this.setState({ professionals: [] })
    const request = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      idEspecialidad: specialtyId,
      idObraSocial: this.props.patient.idObraSocial,
      idPlan: this.props.patient.idPlan,
      esVideoconsulta: this.props.appointment.isOnline
    }

    const [err, { data } = {}] = await to(getProfessionals(request))
    if (!err) {
      this.setState({ professionals: data })
    }
  }

  handleCancel = () => this.props.history.goBack()

  handleProfessionalSubmit = values =>
    this.setState({
      specialty: this.state.specialties.find(s => s.idEspecialidad === Number(values.specialty)),
      professionalSelected: this.state.professionals.find(
        p => p.idProfesional === Number(values.professional)
      ),
      activeTab: 2
    })

  handleScheduleSubmit = ({ schedule: appointment }) => {
    this.setState({
      schedule: appointment.fechaTurnoDateTime,
      appointmentClinic: appointment.clinica,
      professional: this.state.professionals.find(
        p => p.idProfesional === appointment.idProfesional
      ),
      activeTab: 3,
      appointmentId: appointment.idTurno
    })
  }

  handleCalendarGoBack = () =>
    this.setState({
      specialty: '',
      professional: '',
      professionalSelected: '',
      professionals: [],
      activeTab: 1
    })

  handleConfirmGoBack = () => this.setState({ schedule: '', email: '', phone: '', activeTab: 2 })

  getScheduledAppointmentClinic = () => {
    if (this.props.appointment.isOnline) {
      return this.state.appointmentClinic
    }
    else {
      return this.props.appointment.activeClinic.codigoEmpresa
    }
  }

  handleConfirmSubmit = async values => {
    this.setState({ ...values })

    const params = {
      clinica: this.getScheduledAppointmentClinic(),
      nombreClinica: this.props.appointment.activeClinic.nombre,
      direccionClinica: this.props.appointment.activeClinic.direccion,
      idEspecialidad: this.state.specialty.idEspecialidad,
      nombreEspecialidad: this.state.specialty.nombre,
      idProfesional: this.state.professional.idProfesional,
      nombreProfesional: this.state.professional.nombreProfesional,
      idObraSocial: this.props.patient.idObraSocial,
      coberturaMedica: this.props.patient.coberturaMedica,
      plan: this.props.patient.planLineaCartilla,
      idPlan: this.props.patient.idPlan,
      idPaciente: this.props.patient.idPaciente,
      idSistema: '',
      fechaHora: this.state.schedule,
      nroCobertura: this.props.patient.nroCobertura,
      nroAfiliado: this.props.patient.nroAfiliado,
      nombreCompletoPaciente: this.props.patient.nombreApellido,
      nombrePaciente: this.props.patient.nombres,
      nroDocumento: this.props.patient.numeroDocumento,
      telefono: this.state.phone,
      mail: this.state.email,
      esVideoconsulta: this.props.appointment.isOnline,
      idTurno: this.state.appointmentId
    }
    const [err, { data } = {}] = await to(confirmAppointment(params))
    if (!err) {
      this.props.setAppointmentDetails({
        specialty: this.state.specialty,
        professional: this.state.professional,
        phone: this.state.phone,
        email: this.state.email,
        schedule: this.state.schedule,
        clinic: this.state.appointmentClinic, // added for VC
        id: data
      })

      if (this.props.appointment.isOnline) {
        this.props.history.push('/inicio/videoconsulta/turnos/confirmacion')
      } else {
        this.props.history.push(`/inicio/gestionar-turnos/${this.props.match.path.split('/')[3]}/confirmacion`)
      }
    }
  }

  render() {

    return (
      <Content>
        <AppointmentHeader
          fullName={this.props.patient.nombreApellido}
          healthInsurance={this.props.patient.coberturaMedica}
          memberNumber={this.props.patient.nroAfiliado}
        />
        <TabNewAppointment
          listTabs={[
            { id: 1, name: 'Elegí especialidad y médico' },
            { id: 2, name: 'Elegí día y hora' },
            { id: 3, name: 'Confirmá' }
          ]}
          activeTab={this.state.activeTab}
        />
        {this.state.activeTab === 1 && (
          <Fragment>
            <FormSelectProfessional
              specialties={this.state.specialties}
              professionals={this.state.professionals}
              onSpecialtyChange={this.handleSpecialtyChange}
              onCancel={this.handleCancel}
              onSubmit={this.handleProfessionalSubmit}
              isOnline={this.props.appointment.isOnline}
            />
          </Fragment>
        )}
        {this.state.activeTab === 2 && (
          <ScheduleAppointment
            specialty={this.state.specialty.idEspecialidad}
            professional={this.state.professionalSelected.idProfesional}
            onSubmit={this.handleScheduleSubmit}
            onCancel={this.handleCalendarGoBack}
          />)
        }
        {
          this.state.activeTab === 3 && (
            <FormConfirmAppointment
              onCancel={this.handleConfirmGoBack}
              onSubmit={this.handleConfirmSubmit}
              specialty={this.state.specialty}
              professional={this.state.professional}
              clinic={this.props.appointment.activeClinic}
              patient={this.props.patient}
              schedule={this.state.schedule}
              isOnline={this.props.appointment.isOnline}
            />
          )
        }
      </Content >
    )
  }
}

const mapDispatchToProps = { setAppointmentDetails, setAppointmentStage }
function mapStateToProps({ appointment, patient }) {
  return { appointment, patient }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewAppointment)

const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`

const P = styled(Paragraph)`
  text-align:center;
  margin: 10px 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
  }

  ${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
      font-size: 22px;
      margin-bottom: 20px;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font-size: 22px;
      }
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  ${props =>
    props.small &&
    css`
      font-size: 14px;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font-size: 12px;
      }
    `};
  > time {
    font-weight: 600;
  }
`

const ContainerInfo = styled.div`
  width: 450px;
  text-align: center;
  margin: auto;
  marging-bottom:10px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
